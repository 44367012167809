<template>
  <article class="reset-page ndl-flex ndl-flex--column ndl-flex--middle">
    <Logo style="position: absolute; top: 30px; left: 50px;" />
    <div class="ndl-text-large" style="margin: 160px 0 50px">
      重置密码
    </div>
    <a-steps
      :current="current"
      size="small"
      label-placement="vertical"
      style="width: 580px; margin-bottom: 30px"
    >
      <a-step title="选择重置方式" />
      <a-step title="验证身份" />
      <a-step title="设置新密码" />
      <a-step title="完成" />
    </a-steps>
    <!-- 第一步 -->
    <ul v-if="current === 0" class="select-reset-type">
      <li
        class="reset-type ndl-padding ndl-flex ndl-flex--middle"
        @click="current = 1"
      >
        <i class="icon-prefix ndl-icon-smartphone"></i>
        <div class="ndl-flex-item--grow ndl-margin-left">
          <div class="title">使用手机号码重置密码</div>
          <div class="ndl-text-desc-small">短信验证手机</div>
        </div>
        <i class="icon-suffix ndl-icon-chevron-right"></i>
      </li>
      <li
        class="reset-type ndl-padding ndl-flex ndl-flex--middle"
        @click="current = 1"
      >
        <i class="icon-prefix ndl-icon-mail"></i>
        <div class="ndl-flex-item--grow ndl-margin-left">
          <div class="title">使用邮箱重置密码</div>
          <div class="ndl-text-desc-small">发送验证码到邮箱</div>
        </div>
        <i class="icon-suffix ndl-icon-chevron-right"></i>
      </li>
      <div style="text-align: center; margin-top: 30px">
        <router-link to="/auth/login">去登陆</router-link>
      </div>
    </ul>
    <!-- 第二步 -->
    <a-form v-else-if="current === 1" style="width: 320px">
      <a-form-item label="手机号">
        <a-input
          v-model="form.username"
          size="large"
          placeholder="注册时使用的手机号码"
          type="tel"
        >
          <i slot="prefix" class="ndl-icon-phone"></i>
        </a-input>
      </a-form-item>
      <a-form-item label="验证码">
        <InputCaptcha v-model="form.code" :username="form.username" />
      </a-form-item>
      <a-button
        type="primary"
        size="large"
        block
        class="ndl-margin-top-lg"
        @click="current = 2"
      >
        下一步
      </a-button>
      <div class="ndl-flex ndl-flex--between ndl-margin-top">
        <a @click="current = 0">重新选择验证方式</a>
        <router-link to="/auth/login">去登陆</router-link>
      </div>
    </a-form>
    <!-- 第三步 -->
    <a-form v-if="current === 2" style="width: 320px">
      <a-form-item label="设置新密码">
        <a-input-password
          size="large"
          placeholder="请输入新登录密码"
          autocomplete="new-password"
        >
          <i slot="prefix" class="ndl-icon-lock"></i>
        </a-input-password>
      </a-form-item>
      <a-form-item label="确认新密码">
        <a-input-password size="large" placeholder="请再次输入新密码">
          <i slot="prefix" class="ndl-icon-lock"></i>
        </a-input-password>
      </a-form-item>
      <a-button
        type="primary"
        size="large"
        block
        class="ndl-margin-top-lg"
        @click="current = 3"
      >
        重置密码
      </a-button>
    </a-form>
    <!-- 第四步 -->
    <a-result
      v-if="current === 3"
      status="success"
      title="密码已重置"
      sub-title="请使用新密码登陆"
    >
      <a-button slot="extra" type="primary" @click="gotoLogin()">
        去登陆
      </a-button>
    </a-result>
  </article>
</template>

<script>
import InputCaptcha from "@/components/input-captcha";
import Logo from "./Logo";

export default {
  components: { Logo, InputCaptcha },
  data() {
    return {
      current: 0,
      form: {
        username: "",
        code: ""
      }
    };
  },
  methods: {
    gotoLogin() {
      window.location = "/auth/login";
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.reset-page {
  height: 100%;
  min-height: 520px;
  background: url("~@/assets/images/bg_circle.png") repeat;

  .select-reset-type {
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
      border: solid 1px @ndl-border-color;
      border-radius: 4px;
      cursor: pointer;
      width: 360px;
      margin: 1em 0;
      transition: border-color 0.2s;
    }
    & > li:hover {
      border-color: @blue-5;
    }
    .icon-prefix {
      font-size: 28px;
    }
    .title {
      font-size: 16px;
      color: @heading-color;
    }
  }
}
</style>
