<template>
  <article class="login-page">
    <div class="banner">
      <Logo width="315px" />
    </div>
    <LoginForm @on-login="afterLogin()" />
  </article>
</template>

<script>
import store from "@/store";
import Logo from "./Logo";
import LoginForm from "./LoginForm";

export default {
  components: { Logo, LoginForm },
  // 若已登陆，自动跳转首页
  async beforeRouteEnter(to, from, next) {
    await store.updateUserInfo();
    next(store.userInfo ? "/" : true);
  },
  methods: {
    afterLogin() {
      location = this.$route.query.referrer || "/";
    }
  }
};
</script>

<style lang="less" scoped>
.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  min-height: 520px;
  padding-right: 140px;
  background: url("~@/assets/images/bg_circle.png") repeat;
  .banner {
    position: absolute;
    left: 5vw;
    transform-origin: center;
    width: 350px;
    height: 350px;
    background-image: url("~@/assets/images/ai_analysis_data.svg");
    background-repeat: no-repeat;
    background-position: center 80px;
    background-size: 100%;
    animation: antFadeIn 1.3s;
    transition: all 0.3s;
    text-align: center;
  }
  @media screen and (min-width: 1200px) {
    .banner {
      left: 10vw;
      width: 430px;
      height: 430px;
    }
  }
  @media screen and (max-width: 950px) {
    .banner {
      display: none;
    }
    & {
      align-items: center;
      padding-right: 0;
    }
  }
}
</style>
