<template>
  <!-- 密码登陆，submit 事件不触发，就用 keydown 代替了 -->
  <a-form
    v-if="form.grant_type === 'aes_pwd'"
    :colon="false"
    @keydown.enter.native="login()"
  >
    <slot name="header"></slot>
    <!-- divider -->
    <a-divider>密码登录</a-divider>
    <!-- username -->
    <a-form-item label="账号" v-bind="decorator.username">
      <a-input
        v-model="form.username"
        size="large"
        placeholder="用户名 / 邮箱"
        @change="$delete(decorator, 'username')"
      >
        <i slot="prefix" class="ndl-icon-user"></i>
      </a-input>
    </a-form-item>
    <!-- password -->
    <a-form-item label="密码" v-bind="decorator.password">
      <a-input-password
        v-model="form.password"
        size="large"
        placeholder="请输入登录密码"
        @change="$delete(decorator, 'password')"
      >
        <i slot="prefix" class="ndl-icon-lock"></i>
      </a-input-password>
    </a-form-item>
    <a-button
      type="primary"
      size="large"
      block
      class="ndl-margin-top"
      :loading="loading"
      @click="login()"
    >
      登录
    </a-button>
    <div class="ndl-flex ndl-flex--between ndl-margin-top">
      <a @click="toggleType('mail')">验证码登陆</a>
      <router-link to="/auth/reset">忘记密码?</router-link>
    </div>
  </a-form>
  <!-- 邮箱验证码登陆 -->
  <a-form
    v-else-if="form.grant_type === 'mail'"
    :colon="false"
    @keydown.enter.native="login()"
  >
    <slot name="header"></slot>
    <!-- divider -->
    <a-divider>验证码登录</a-divider>
    <!-- username -->
    <a-form-item label="邮箱 / 手机" v-bind="decorator.username">
      <a-input
        v-model="form.username"
        size="large"
        placeholder="邮箱 / 手机"
        @change="$delete(decorator, 'username')"
      >
        <i slot="prefix" class="ndl-icon-mail"></i>
      </a-input>
    </a-form-item>
    <!-- password -->
    <a-form-item label="验证码" v-bind="decorator.code">
      <InputCaptcha
        v-model="form.code"
        :username="form.username"
        @input="$delete(decorator, 'code')"
      />
    </a-form-item>
    <a-button
      type="primary"
      size="large"
      block
      class="ndl-margin-top"
      :loading="loading"
      @click="login()"
    >
      登录
    </a-button>
    <div class="ndl-flex ndl-flex--between ndl-margin-top">
      <a @click="toggleType('aes_pwd')">密码登陆</a>
      <router-link to="/auth/reset">忘记密码?</router-link>
    </div>
  </a-form>
</template>

<script>
import { login } from "@/api/auth";
import InputCaptcha from "@/components/input-captcha";
import token from "@/utils/token";
import encrypt from "@/utils/encrypt";
import cache from "lscache";

export default {
  components: { InputCaptcha },
  data() {
    return {
      form: this.getModel(),
      loading: false,
      decorator: {}
    };
  },
  methods: {
    getModel(type) {
      return {
        username: "",
        code: "",
        grant_type: type || cache.get("auth.login_type") || "aes_pwd"
      };
    },
    toggleType(type) {
      this.form = this.getModel(type);
      this.decorator = {};
      cache.set("auth.login_type", type);
    },
    validate() {
      const form = this.form || {};
      const decorator = {};
      let valid = true;
      function assert(prop, help, proxy) {
        if (form[prop]) return;
        decorator[proxy || prop] = { help, validateStatus: "error" };
        valid = false;
      }
      const isByPassword = form.grant_type === "aes_pwd";
      // 检查用户名 / 邮箱
      assert("username", isByPassword ? "请输入用户名 / 邮箱" : "请输入邮箱");
      // 检查密码或验证码
      assert(
        isByPassword ? "password" : "code",
        isByPassword ? "请输入登陆密码" : "请输入验证码"
      );
      this.decorator = decorator;
      return valid;
    },
    login() {
      if (!this.validate()) return;
      this.loading = true;
      const form = this.form;
      login({ ...form, password: encrypt(form.password) })
        .then(({ data }) => {
          token.set(data);
          token.updateAxiosHeaders();
          this.loading = false;
          this.$emit("on-login");
        })
        .catch(({ data }) => {
          this.loading = false;
          const decorator = {};
          const error = help => ({ help, validateStatus: "error" });
          switch (data.code) {
            // 验证码错误
            case "41001":
              decorator.code = error("验证码错误");
              break;
            // 验证码失效
            case "41002":
              decorator.code = error("验证码失效，请重新获取");
              break;
            // 用户名或密码错误
            case "42004":
              decorator.username = error("用户名或密码错误");
              decorator.password = error("用户名或密码错误");
              break;
            // 未知错误
            case "40000":
              decorator.username = error("登陆失败，请检查输入是否正确");
              decorator.password = error("登陆失败，请检查输入是否正确");
              decorator.code = error("登陆失败，请检查输入是否正确");
              break;
          }
          this.decorator = decorator;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.ant-form {
  width: 360px;
}
.ant-divider-with-text-center {
  color: rgba(0, 0, 0, 0.25);
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 40px;
}

.ant-form-item /deep/ .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.45);
}
</style>
